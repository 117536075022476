@import "../../utils/mixins.scss";

@import "../../utils/mixins.scss";

.Signup {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__login {
    @include heading5;
    font-weight: 400;
    text-align: right;
    cursor: pointer;
    color: #3c7bf6;
  }

  &__body {
    flex: 1;
    background-color: var(--page-bg-color) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 104px);
    width: 100%;
    align-items: center;
    min-height: 500px;
    &__page-title {
      @include heading2;
    }
  }
  &__fp-title {
    @include heading1;
    color: #261d56;
    font-weight: 500;
    justify-content: center;
    display: flex;
  }
  &__card {
    background-color: #fff;
    width: 433px;
    display: flex;
    flex-direction: column;
    padding: 3em;
    border-radius: 10px;
    box-shadow: 1px 1px 9px 1px lightgrey;
  }
  &__btn {
    @include general-button;
    width: 100%;
    color: #fff;
    border: none;
    padding: 0.5rem;
    font-weight: 600;
    height: 48px;
    transition: 0.3s;
    &:hover:enabled {
      color: white;
    }
  }

  @media only screen and (max-width: 726px) {
    &__card {
      width: 95%;
      padding: 20px;
      box-shadow: 1px 1px 9px 1px #fff;
    }
    &__body {
      padding: 0;
      height: 100%;
    }
  }
}
